<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="{ name: 'TourAdminsIp', query: this.createSearchQuery()}" class="ashen-link">大会管理者/関係者管理
          </router-link>
          <i class="fas fa-angle-right form-control-color"></i> 大会管理者/関係者情報
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevUserString" class="col-6">
            <router-link :to="linkPrevUser" class="ashen-link mx-1" replace>
              <i class="fas fa-angle-left"></i>
              {{ prevUserString }}
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextUserString" class="col-6 text-end">
            <router-link :to="linkNextUser" class="ashen-link mx-1" replace>
              {{ nextUserString }}<i class="fas fa-angle-right mx-1"></i>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>

    <div class="card mt-3">
      <div class="card-header bg-transparent">
        <img src="../assets/images/racket-gray.png"/>
        <span class="mx-3">{{ tournamentName }}</span>
      </div>
      <div class="card-body px-5 mt-4 m-l-100 m-r-100">
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">入場管理番号</label>
            <input
                v-model="ipUser.user.userId"
                class="form-control mt-2 full-width-input"
                disabled
                type="text"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="lastName2">姓 名</label>
            <input id="lastName2"
                   v-model="ipUser.user.lastName"
                   class="form-control mt-2 half-width-input"
                   disabled
                   type="text"
            />
          </div>
          <div class="col">
            <label for="firstName2"></label>
            <input id="firstName2"
                   v-model="ipUser.user.firstName"
                   class="form-control mt-2 half-width-input"
                   disabled
                   type="text"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">メールアドレス</label>
            <input
                v-model="ipUser.user.email"
                class="form-control mt-2 full-width-input"
                disabled
                type="email"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">生年月日</label>
            <input
                v-model="ipUser.user.birthday"
                class="form-control mt-2 full-width-input"
                disabled
                type="text"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label>ロール</label>
            <select
                v-model="ipUser.userRoleId"
                class="form-control select-role mt-2 select-style form-select"
            >
              <option
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="role.roleId"
              >
                {{ role.roleName }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">申告事項</label>
            <input
                v-model="ipUser.organization"
                class="form-control mt-2 full-width-input"
                type="text"
            />
          </div>
          <div v-show="errors['organization']" id="err_organization" class="form-error">{{ errors['organization'] }}</div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">性別</label>
            <input
                v-model="gender"
                class="form-control mt-2 full-width-input"
                disabled
                type="text"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">国籍</label>
            <input
                v-model="ipUser.user.nationalityName"
                class="form-control mt-2 full-width-input"
                disabled
                type="text"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">住所</label>
            <input
                v-model="ipUser.user.address"
                class="form-control mt-2 full-width-input"
                disabled
                type="text"
            />
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">電話番号</label>
            <input
                v-model="ipUser.user.phoneNo"
                class="form-control mt-2 half-width-input"
                disabled
                type="text"
            />
          </div>
        </div>
<!--
        <div class="row mt-4">
          <label for="exampleInputPassword1">新型コロナワクチン接種</label>
          <div class="col">

            <input
                v-model="coronaVaccinationCount"
                class="form-control mt-2 half-width-input"
                disabled
                type="text"
            />
          </div>
          <div class="col">
            <input
                v-model="ipUser.user.latestCoronaVaccinationDate"
                class="form-control mt-2 half-width-input"
                disabled
                type="text"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">PCR検査</label>
            <input
                v-model="pcrTestResultStatus"
                class="form-control mt-2 half-width-input"
                disabled
                type="text"
            />
          </div>
          <div class="col">
            <label for="exampleInputPassword1"></label>
            <input
                v-model="ipUser.user.latestPcrTestDate"
                class="form-control mt-2 half-width-input"
                disabled
                type="text"
            />
          </div>
        </div>
        -->
        <div class="row medical-history mt-2">
          <div class="col">
            <div class="form-group">
              <label>既往歴について</label>
              <div class="m-t-10">
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="anemia"
                      v-model="ipUser.user.anemia"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="anemia">貧血</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="asthma"
                      v-model="ipUser.user.asthma"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="asthma">ぜんそく</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="heartDisease"
                      v-model="ipUser.user.heartDisease"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="heartDisease">心臓病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="kidneyDisease"
                      v-model="ipUser.user.kidneyDisease"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="kidneyDisease">腎臓病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="epilepsy"
                      v-model="ipUser.user.epilepsy"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="epilepsy">てんかん</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="highBloodPressure"
                      v-model="ipUser.user.highBloodPressure"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="highBloodPressure">高血圧</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="diabetes"
                      v-model="ipUser.user.diabetes"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                  />
                  <label class="form-check-label" for="diabetes">糖尿病</label>
                </div>
                <div class="form-check mx-auto m-t-10">
                  <input
                      id="medicalHistoryOther"
                      v-model="ipUser.user.other"
                      class="form-check-input"
                      disabled
                      type="checkbox"
                      @click="ipUser.user.other!==ipUser.user.other"
                  />
                  <label class="form-check-label" for="medicalHistoryOther">その他</label>
                </div>

                <div v-if="ipUser.user.other" class="form-check p-l-0 mx-auto">
                  <textarea id="otherText" v-model="ipUser.user.otherText" class="form-control" disabled
                            rows="2"></textarea>
                  <div v-show="errors['otherText']" id="err_other" class="form-error">{{ errors['otherText'] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col form-check p-l-35">
            <input
                id="flexCheckDefault"
                v-model="ipUser.pastAnswersRequired"
                class="form-check-input"
                type="checkbox"
                value=""
            />
            <label class="form-check-label" for="flexCheckDefault">
              過去14日間における回答をさせる
            </label>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-4">
            <div class="btn-width">
              <Modal :admin-user-delete="deleteUser"
                     :back-to-root="backToTourAdminsIp"
                     :data="ipUser"
                     :reload-data="getData"
                     body="  を大会から削除してもよろしいですか？ "
                     button-title="削除"
                     cancel-title="キャンセル"
                     ok-title="削除" title="削除"></Modal>
            </div>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 btn-width"
                    @click="backToTourAdminsIp">
              キャンセル
            </button>
            <button class="btn col btn-primary btn-outline-light font-weight-bold btn-width float-end"
                    @click="updateUser">変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {datatable, defaults} from "@/constants";
import Datatable from "../components/datatable/Datatable";
import {mapGetters} from "vuex";
import Common from "../assets/jsadminclient/common";
import Helper from "../assets/jsadminclient/helper";
import Modal from '../components/modal/Modal'

export default {
  name: "TourAdminsIpUserDetail",
  components: {Datatable, Modal},
  props: {
    tournamentId: {type: [Number, String], default: null},
    userId: {type: String, default: null}
  },
  data() {
    return {
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      response: {
        current_page: 1,
        sort: 'userId',
        order: 'desc',
        per_page: 20
      },
      deleteSuccess: false,
      deleteFailed: false,
      organization: '',
      gender: '',
      loading: true,
      addNewPartyScreen: false,
      addNewBulkPartyScreen: false,
      batchRegistrationConfirmationScreen: false,
      batchRegistrationSuccessMsgScreen: false,
      tournamentListScreen: false,
      columns: datatable.columns.touradminsip,
      tournamentList: {},
      tournamentName: '',
      nextUserId: '',
      nextUserName: '',
      prevUserId: '',
      prevUserName: '',
      coronaVaccinationStatus: "",
      coronaVaccinationCount: null,
      pcrTestResultStatus: '',
      ipUser: { user: {} },
      roles: {},
      roleId: defaults.roleId,
      user: {},
      reloadKey: 1,
      year: "",
      month: "",
      date: "",
      uploadResponseMessage: [],
      errors: []
    };
  },
  computed: {
    ...mapGetters(["config"]),
    prevUserString() {
      return this.prevUserName ? this.prevUserName + '(' + this.prevUserId + ')' : ''
    },
    nextUserString() {
      return this.nextUserName ? this.nextUserName + '(' + this.nextUserId + ')' : ''
    },
    linkPrevUser() {
      return {
        name: "TourAdminsIpUserDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.prevUserId,
        },
        query: this.createSearchQuery()
      }
    },
    linkNextUser() {
      return {
        name: "TourAdminsIpUserDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.nextUserId,
        },
        query: this.createSearchQuery()
      }
    }
  },
  watch: {
    $route(to, from) {
      // Called from back or next
      this.initialPageLoadHandler()
    }
  },
  mounted() {
    this.initialPageLoadHandler();
  },
  methods: {
    initialPageLoadHandler() {
      this.getRoles()
      this.getUser()
      this.getTournament()
    },
    backToTourAdminsIp() {
      this.$router.push({name: 'TourAdminsIp', query: this.createSearchQuery()})
    },
    async updateUser() {
      try {
        this.errors = [];
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdIpUsersIpUserIdPostAsync(
            this.tournamentId,
            this.userId,
            this.ipUser
        );
        Common.showToast("大会管理者/関係者の情報が変更されました。")
        this.backToTourAdminsIp();
        this.getData();
      } catch (e) {
        this.loading = false;
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async deleteUser() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdIpUsersIpUserIdDeleteAsync(
            this.tournamentId,
            this.userId,
            this.ipUser
        );
        Common.showToast("大会管理者/関係者を削除しました。")
        if (result) {
          this.loading = false
        }
      } catch (e) {
        this.loading = false;
        this.backToTourAdminsIp()

        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },

    async getData() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdIpUsersGetAsync(
            this.tournamentId,
            this.roleId,
            this.accountStatus,
            this.keyword,
            this.response.sort,
            this.response.order,
            this.current_page,
            this.per_page
        )

        this.loading = true;
        if (result.items) {
          this.loading = false
          this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          // // map user data
          this.response.data = result.items.map(function (item) {
            return {
              userId: item.user.userId,
              lastName: item.user.lastName,
              firstName: item.user.firstName,
              email: item.user.email,
              isJTA: item.user.isJTA,
              isTournamentAdmin: item.isTournamentAdmin,
            }
          })
        }
      } catch (e) {
        this.response.data = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getUser() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.ipUser = await api.tournamentsTournamentIdIpUsersIpUserIdGetAsync(
            this.tournamentId,
            this.userId,
            this.$route.query.role,
            this.$route.query.accountStatus,
            this.$route.query.keyword,
            this.$route.query.sort,
            this.$route.query.order,
        );
        this.gender = Common.getGender(this.ipUser.user.gender)
        this.coronaVaccinationCount = Common.getHasCoronaVaccination1(this.ipUser.user.coronaVaccinationCount)
        this.organization = this.organization
        this.nextUserId = this.ipUser.nextUserId
        this.nextUserName = this.ipUser.nextUserName
        this.prevUserId = this.ipUser.prevUserId
        this.prevUserName = this.ipUser.prevUserName

        this.pcrTestResultStatus = Common.getLatestPcrTestResult(this.ipUser.user.latestPcrTestResult)

      } catch (e) {
        this.ipUser = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getRoles() {
      try {
        const api = new AdminApi.SystemApi(Helper.apiConfig(this.config.token));
        var result = await api.systemRolesGetAsync();
        this.roles = result.items;
      } catch (e) {
        this.roles = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournament() {
      try {
        const api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsListTournamentIdGetAsync(
            this.tournamentId,
        );
        this.tournamentName = result.tournamentName
      } catch (e) {
        this.tournamentName = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  font-size: 16px;
}

.delete {
  width: 160px;
  height: 44px;
}

.form-control:disabled {
  color: #333333 !important;
}

.select-role.form-control {
  background: #F2ECF0;
  color: #333333;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.form-check {
  padding-left: 33px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #f2ecf0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.fa-upload {
  color: #f2ecf0;
}

.button-container {
  padding: 0 190px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  background: #f2ecf0;
  border: none;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #f8f8f8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #f0e7ed;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0 !important;
  }
}

.filter-select,
.filter-search-form,
.btn-search {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  border: 1px solid #dee2e6;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.content {
  &.touradminsip {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }

  &.touradminsipuserdetail {
    .card-body {
      margin-right: 100px !important;
      margin-left: 100px !important;
    }

    .btn-width {
      width: 160px;
    }

    @media only screen and (max-width: 991px) {
      .card-body {
        margin-right: 30px !important;
        margin-left: 30px !important;
      }
      .btn-width {
        width: 140px;
      }
    }
  }
}

.medical-history {
  .form-check {
    padding-left: 23px;
  }
}

.form-check {
  &-input:disabled, &-input[disabled], &-input:disabled ~ &-label, &-input[disabled] ~ &-label {
    opacity: 1;
  }
}
</style>
